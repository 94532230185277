<template>
<!--采购对账管理收货凭证列表  -->
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <list-menu @buttonClick="buttonClick" :menuData="menudata"></list-menu>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @tableAction="tableAction" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData, menudata } from './js/proofofReceipt'
export default {
  components: Component.components,
  name: 'ProofofReceipt',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      childData: { searchList: SearchList, searchData: SearchData },
      searchdata: SearchData,
      tableField: tableField,
      tableData: tableData,
      menudata: menudata,
      paginationData: {},
      pageNum: 1,
      pageSize: pageSize

    }
  },
  mounted () {
    this.ProofofReceiptList()
  },
  methods: {
    tableAction (index, data, action) {
      this.$confirm('确定转为人工创建对账单？', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        requestForm('/api/reconciliation/reconciliation/updateSystem', 'post', { ids: data.id }).then((res) => {
          if (res.code === '200') {
            this.$message({
              message: '修改成功',
              type: 'success'
            })
            this.ProofofReceiptList()
          }
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    ProofofReceiptList () {
      var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
      if (pageSize < 10) {
        pageSize = 10
      }
      requestForm('/api/reconciliation/reconciliation/queryAll?' + 'pageNum=' + this.pageNum + '&' + 'pageSize=' + this.pageSize, 'post').then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          for (const item of this.tableData) {
            if (item.reStatus === 0 && item.isAutomatic === 0 && item.consignmentOrNot !== 0) {
              item.judgeEdit = 2
            } else {
              item.judgeEdit = 5
            }
          }
          this.paginationData = { pageSize: pageSize, total: res.data.total }
        }
      })
    },
    buttonClick () {
      this.$router.push({ params: { viewName: 'CreateaStatement' } })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.postingDate
      if (data.postingDate) {
        this.searchdata.strEndDate = this.dateFormate(data.postingDate[1])
        this.searchdata.strStartDate = this.dateFormate(data.postingDate[0])
      }
      requestForm('/api/reconciliation/reconciliation/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          for (const item of this.tableData) {
            if (item.reStatus === 0 && item.isAutomatic === 0 && item.consignmentOrNot !== 0) {
              item.judgeEdit = 2
            } else {
              item.judgeEdit = 5
            }
          }
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      requestForm('/api/reconciliation/reconciliation/queryAll', 'post', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          for (const item of this.tableData) {
            if (item.reStatus === 0 && item.isAutomatic === 0 && item.consignmentOrNot !== 0) {
              item.judgeEdit = 2
            } else {
              item.judgeEdit = 5
            }
          }
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">

</style>
