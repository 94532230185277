import md5 from 'js-md5'
export var SearchList = [
  { labe: '公司名称', code: 'corporateName', type: 'input', placeholder: '编码/名称' },
  { labe: '供应商', code: 'supplier', type: 'input', placeholder: '编码/名称' },
  { labe: '开票方', code: 'billingParty', type: 'input', placeholder: '编码/名称' },
  { labe: '采购单编号', code: 'purchaseOrderNumber', type: 'input' },
  {
    labe: '事务类型',
    code: 'transactionType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '收货', value: '0' },
      { label: '退货', value: '1' }
    ]
  },
  {
    labe: '过账日期',
    code: 'postingDate',
    type: 'daterange'
  },
  { labe: '物料编号', code: 'itemNumber', type: 'input' },
  { labe: '物料凭证号', code: 'materialVoucherNo', type: 'input' },
  {
    labe: '状态',
    code: 'reStatus',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '未对账', value: '0' },
      { label: '已对账', value: '1' }
    ]
  },
  {
    labe: '对账单类型',
    code: 'statementType',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '正常采购', value: '0' },
      { label: '分期付款', value: '1' }
    ]
  },
  {
    labe: '创建对账单',
    code: 'isAutomatic',
    type: 'select',
    option: [
      { label: '全部', value: '' },
      { label: '系统创建', value: '0' },
      { label: '人工创建', value: '1' }
    ]
  }
]
export var SearchData = {
  corporateName: '',
  supplier: '',
  billingParty: '',
  purchaseOrderNumber: '',
  transactionType: '',
  strStartDate: '',
  strEndDate: '',
  itemNumber: '',
  materialVoucherNo: '',
  reStatus: '',
  statementType: '',
  isAutomatic: ''
}

export var menudata = [{
  label: '+创建对账单',
  action: 'CreateaStatement',
  id: 1
}]

export var tableField = [
  { label: '物料凭证号', code: 'materialVoucherNo', type: 'input', width: '138' },
  { label: '凭证行号', code: 'materialVoucherLineNumber', type: 'input', width: '' },
  {
    label: '状态',
    code: 'reStatus',
    type: 'function',
    width: '',
    handle: (index, data) => {
      const color = md5(data.reStatus + '').substring(20, 26)
      const label = data.reStatus === 0 ? '未对账' : '已对账'
      const tblab = `<div class="dotlab"><div class="dot" style="background:#${color}"></div>${label}</div>`
      return tblab
    }
  },
  {
    label: '对账单类型',
    code: 'statementType',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.statementType === 0 ? '正常采购' : '分期付款'
    }
  },
  { label: '采购单编号', code: 'purchaseOrderNumber', type: 'input', width: '115' },
  { label: '采购单行号', code: 'purchaseLineNumber', type: 'input', width: '105' },
  { label: '物料编号', code: 'itemNumber', type: 'input', width: '120' },
  { label: '物料描述', code: 'materialDescription', type: 'input', width: '110' },
  { label: '收货数量', code: 'quantityReceived', type: 'input', width: '100' },
  { label: '已对账数量', code: 'reconciledQuantity', type: 'input', width: '100' },
  { label: '单价', code: 'unitPrice', type: 'amount', align: 'right', width: '80' },
  { label: '收货总额', code: 'amountIncludingTax', type: 'amount', align: 'right', width: '' },
  { label: '已对账含税金额', code: 'reconciledAmountIncludingTax', type: 'amount', align: 'right', width: '120' },
  {
    label: '是否寄售',
    code: 'consignmentOrNot',
    type: 'function',
    width: '80',
    handle: (index, data) => {
      return data.consignmentOrNot === 0 ? '是' : '否'
    }
  },
  { label: '付款条件', code: 'termOfPayment', type: 'input', width: '' },
  { label: '过账日期', code: 'postingDate', type: 'input', width: '110' },
  {
    label: '事务类型',
    code: 'transactionType',
    type: 'function',
    width: '',
    handle: (index, data) => {
      return data.transactionType === 0 ? '收货' : '退货'
    }
  },
  {
    label: '公司名称',
    code: 'corporateName',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.corporateCode}-${row.corporateName}`
    }
  },
  {
    label: '供应商名称',
    code: 'supplier',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.supplierCode}-${row.supplier}`
    }
  },
  {
    label: '开票方',
    code: 'billingParty',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.billingPartyCode}-${row.billingParty}`
    }
  },
  {
    label: '创建对账单',
    code: 'isAutomatic',
    type: 'function',
    width: '120',
    handle: (index, data) => {
      return data.isAutomatic === 0 ? '系统创建' : '人工创建'
    }
  },
  { label: '币种', code: 'currency', type: 'input', width: '' },
  { label: '基地', code: 'base', type: 'input', width: '' },
  { label: '内部项目编号', code: 'internalProjectNumber', type: 'input', width: '110' },
  { label: '内部项目标识', code: 'internalProjectIdentification', type: 'input', width: '110' },
  { label: '对账单编号', code: 'number', type: 'input', width: '110' },
  { label: '网上发票号', code: 'onlineInvoiceNumber', type: 'input', width: '200' },
  { label: '资产编号', code: 'assetNumber', type: 'input', width: '' },
  {
    label: '操作',
    code: 'act',
    width: '',
    type: 'action',
    fixed: 'right',
    actionData: [
      {
        actionLabel: '转人工创建',
        id: '1',
        stateField: 'judgeEdit',
        stateSymbol: '=',
        stateValue: '2'

      }
    ]
  }
]

export var tableData = []
